import connect from '../../utils/saga/connect';
import * as credentialListener from './sagas/credentialListener';
import * as checkAuth from './sagas/checkAuth';
import * as signIn from './sagas/signIn';
import * as signUp from './sagas/signUp';
import * as logOut from './sagas/logOut';
import * as oauth from './sagas/oauth';
import * as passwordReset from './sagas/passwordReset';
import * as passwordUpdate from './sagas/passwordUpdate';
import * as registrationComplete from './sagas/registrationComplete';
import * as step1 from './sagas/step1';

export default connect([credentialListener, checkAuth, signIn, signUp, logOut, oauth, passwordReset, passwordUpdate, registrationComplete, step1]);
