import { DateTime } from 'luxon';
import { type Task } from 'redux-saga';
import { type SagaReturnType, takeEvery, cancel, put, fork, delay } from 'redux-saga/effects';

import log from '../model/log';

import * as actions from '../actions';

export function* tick() {
  while (true) {
    yield delay(1000);
    yield put(actions.tick());
  }
}

let tickTask: Task | null = null;
let pingTime: DateTime | null = null;
let pongTime: DateTime | null = null;

export const config = {
  action: [actions.opened.type, actions.closed.type, actions.close.type, actions.error.type, actions.sendPing.type, actions.receivePong.type, actions.tick.type],
  method: takeEvery,
};

export function* func(
  action: SagaReturnType<typeof actions.opened | typeof actions.closed | typeof actions.close | typeof actions.error | typeof actions.sendPing | typeof actions.receivePong | typeof actions.tick>,
) {
  if (action.type === actions.opened.type) {
    log('handler:: socket opened');
    if (tickTask) {
      yield cancel(tickTask);
    }
    tickTask = yield fork(tick);
  }

  if (action.type === actions.closed.type) {
    log('handler:: socket closed');
    if (tickTask) {
      yield cancel(tickTask);
      tickTask = null;
    }
    yield put(actions.reconnect());
  }

  if (action.type === actions.close.type) {
    log('handler:: socket closed by server');
    yield put(actions.reconnect());
  }

  if (action.type === actions.error.type) {
    log('handler:: socket error occurred');
    yield put(actions.reconnect());
  }

  if (action.type === actions.sendPing.type) {
    log('handler:: send ping');
    pingTime = DateTime.now();
  }

  if (action.type === actions.receivePong.type) {
    log('handler:: receive pong');
    pongTime = DateTime.now();
  }

  if (action.type === actions.tick.type && pingTime && pongTime && pongTime.diff(pingTime, 'milliseconds').milliseconds > 0) {
    const secondsSincePong = DateTime.now().diff(pongTime, 'seconds').seconds;
    if (secondsSincePong > 30) {
      log(`handler:: pong timeout (${secondsSincePong} seconds)`);
      yield put(actions.reconnect());
    }
  }
}
