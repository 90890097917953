import { type SagaReturnType, takeEvery, cancel, put } from 'redux-saga/effects';

import { call } from 'store/utils/saga/effects';
import network from 'lib/network';

import { actions } from '../slice';
import type { ModelType } from '../types';

export const config = {
  action: actions.loadAvailableModels.type,
  method: takeEvery,
};

export function* func(action: SagaReturnType<typeof actions.loadAvailableModels>) {
  const { data, hasError, errors } = yield* call(() => network.request<ModelType[]>('/models').get());
  if (hasError || !data) {
    yield put(actions.loadAvailableModelsDone());
    yield cancel();
    return;
  }

  yield put(actions.loadAvailableModelsDone({ models: data }));
}
