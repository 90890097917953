import { type ReactElement, memo, useCallback, useRef, useEffect, useState, useMemo } from 'react';
import { StyleSheet } from 'react-native';

import type { UserType } from 'app/entities';

import { useResponsive, useGetOpenLink, useColorScheme } from 'hooks';

import { useDispatcher, useSelector } from 'store/utils/redux/hooks';
import * as userStore from 'store/nodes/user';

import { BooleanInput, Button, Form, PhotoInput, TextInput, DropdownInput } from 'components/Form';

import Button2 from 'components/Button';
import Icon from 'components/LegacyIcon';
import { controller as modal } from 'components/Modal2';
import { unit } from 'utils';
import { Text, View } from 'components/Themed';
import dialog from 'components/Dialog';
import { Typography } from '@mui/joy';

const ProfileSettings = (props: unknown): ReactElement => {
  const dispatcher = useDispatcher();
  const responsive = useResponsive();
  const openLink = useGetOpenLink();
  const formRef = useRef<any>();

  const theme = useColorScheme();
  const userProfile = useSelector(userStore.selectors.getMy);
  const { isLoading: isProfileUpdating, result: profileResult } = useSelector((state) => state.profile.meta.update);
  const { isLoading: isSignOutLoading, result: signOutResult } = useSelector((state) => state.auth.signOut);

  const [isSignOutProcessing, setSignOutProcessing] = useState<boolean>(false);

  useEffect(() => {
    if (isSignOutLoading) {
      setSignOutProcessing(true);
    }
    if (!isSignOutLoading && isSignOutProcessing) {
      modal.popup.profileSettings.close();
    }
  }, [isSignOutProcessing, isSignOutLoading]);

  useEffect(() => {
    formRef?.current?.setValues?.({
      ...userProfile,
      theme,
    });
    setSignOutProcessing(false);
  }, []);

  const handleClose = useCallback(() => {
    modal.popup.profileSettings.close();
  }, []);

  const handleSubmitQuery = useCallback(() => {
    formRef?.current?.submitForm();
  }, []);

  const handleSubmit = useCallback((values: UserType) => {
    dispatcher.profile.updateData(values);
  }, []);

  const handleSignOut = useCallback(() => {
    dispatcher.auth.logOut();
  }, []);

  const handleDeleteAccount = useCallback(() => {
    dialog.show(
      {
        title: 'Delete account',
        text: 'Are you sure want to delete your account? Please note that this operation cannot be undone.',
      },
      [
        {
          label: 'Cancel',
          variant: 'outlined',
          lightColor: '#929292',
          darkColor: '#77767E',
          handle: (dialogId: string) => {
            dialog.hide(dialogId);
          },
        },
        {
          label: 'Delete',
          variant: 'contained',
          lightColor: '#db3327',
          darkColor: '#db3327',
          handle: (dialogId: string) => {
            dialog.hide(dialogId);
            dispatcher.profile.deleteAccount();
          },
        },
      ],
    );
  }, []);

  const handlePrivacyPolicy = useCallback(() => {
    openLink('https://www.iubenda.com/privacy-policy/39334813');
  }, []);

  const handleCookiePolicy = useCallback(() => {
    openLink('https://www.iubenda.com/privacy-policy/39334813/cookie-policy');
  }, []);

  const handleTermsConditions = useCallback(() => {
    openLink('https://www.iubenda.com/terms-and-conditions/39334813');
  }, []);

  const themeOptions = useMemo(
    () =>
      ['Dark  ', 'Light  ', 'System  '].map((item) => ({
        label: item,
        value: item.trim().toLowerCase(),
      })),
    [],
  );

  return (
    <View style={[styles.ProfileSettings, responsive.isMoreThen.mobile && styles.desktop]} lightColor="#e7e7e7" darkColor="#181818" pointerEvents="auto">
      <View style={styles.topBar}>
        <Button type="button" variant="text" style={styles.topBarButton} onPress={handleClose} isLocked={isProfileUpdating}>
          <Typography color="primary">Cancel</Typography>
          {/* <Text darkColor="#497CFF" lightColor="#497CFF" size={15} weight="medium">Cancel</Text> */}
        </Button>
        <Text size={20}>Edit Profile</Text>
        <Button type="button" variant="text" style={styles.topBarButton} onPress={handleSubmitQuery} inProcess={isProfileUpdating}>
          <Text darkColor="#497CFF" lightColor="#497CFF" size={15} weight="medium">
            Done
          </Text>
        </Button>
      </View>
      <Form innerRef={formRef} onSubmit={handleSubmit}>
        <View style={styles.avatar}>
          <PhotoInput name="photo" previewSize={100}>
            <View style={styles.avatarIcon} lightColor="#d2d2d2" darkColor="#232326">
              <Icon name="EditOutline28" size={24} />
            </View>
          </PhotoInput>
        </View>
        <View style={styles.itemText}>
          <Text size={17} weight="semi">
            Personal data
          </Text>
        </View>
        <TextInput name="name" label="Name *" />
        <TextInput name="surname" label="Surname *" />
        <TextInput name="login" label="Nickname *" />
        <TextInput name="phone" label="Phone" />
        <TextInput name="email" label="Email" />
        <TextInput name="location" label="City" />
        {/* <TextInput name="birthDate" label="Birth date" /> */}
        <View style={styles.itemText}>
          <Text size={17} weight="semi">
            Professional data
          </Text>
        </View>
        <TextInput name="profession" label="Profession" />
        <TextInput name="websiteLink" label="Web site" />
        <View style={styles.itemText}>
          <Text size={17} weight="semi">
            Contact
          </Text>
        </View>
        <TextInput name="facebookLink" label="Facebook" />
        <TextInput name="instagramLink" label="Instagram" />
        <TextInput name="telegramLink" label="Telegram" />
        <View style={styles.goalsBoolField}>
          <Text size={17} weight="semi">
            Show my topics in the feed
          </Text>
          <BooleanInput name="isGoalsDisplayed" style={{ marginTop: 0 }} />
        </View>
        <View style={styles.skillsBoolField}>
          <Text size={17} weight="semi">
            Show my topics in profile
          </Text>
          <BooleanInput name="isSkillsDisplayed" style={{ marginTop: 0 }} />
        </View>
        <View style={styles.themeBoolField}>
          <Text size={17} weight="semi">
            Theme
          </Text>
          <DropdownInput name="theme" items={themeOptions} />
        </View>
        <Button type="submit" inProcess={isProfileUpdating} isLocked={isProfileUpdating} radius="full" size={50} style={{ marginTop: unit(48) }}>
          <Text size={17} lightColor="#ffffff" darkColor="#ffffff">
            Update the data
          </Text>
        </Button>
        <Button type="button" inProcess={isSignOutLoading} isLocked={isSignOutLoading} radius="full" size={50} variant="outlined" onPress={handleSignOut} style={{ marginTop: unit(16) }}>
          <Text size={17} lightColor="#797979" darkColor="#B8B6BF">
            Log out
          </Text>
        </Button>
        <View style={styles.itemTextTwo}>
          <Text size={11} lightColor="#888888" darkColor="#9A99A2" align="center">
            By clicking the &quot;Refresh data&quot; button, you consent to the processing of personal data
          </Text>
        </View>
        <Button type="button" style={styles.deleteAccountButton} radius="full" size={40} lightColor="#db3327" darkColor="#db3327" onPress={handleDeleteAccount}>
          <Text size={13} lightColor="#FFFFFF" darkColor="#FFFFFF">
            Delete my account
          </Text>
        </Button>
        <View style={styles.links}>
          <Button2
            type="button"
            variant="text"
            height={20}
            style={styles.link}
            onPress={handlePrivacyPolicy}
            // https://www.iubenda.com/privacy-policy/39334813
          >
            <Text size={13} darkColor="#497CFF" lightColor="#497CFF">
              Privacy Policy
            </Text>
          </Button2>
          <Button2
            type="button"
            variant="text"
            height={20}
            style={styles.link}
            onPress={handleCookiePolicy}
            // https://www.iubenda.com/privacy-policy/39334813/cookie-policy
          >
            <Text size={13} darkColor="#497CFF" lightColor="#497CFF">
              Cookie Policy
            </Text>
          </Button2>
          <Button2
            type="button"
            variant="text"
            height={20}
            style={styles.link}
            onPress={handleTermsConditions}
            // https://www.iubenda.com/terms-and-conditions/39334813
          >
            <Text size={13} darkColor="#497CFF" lightColor="#497CFF">
              Terms & Conditions
            </Text>
          </Button2>
        </View>
      </Form>
    </View>
  );
};

const styles = StyleSheet.create({
  ProfileSettings: {
    width: '100%',
    maxWidth: unit(560),
    alignSelf: 'center',
    paddingHorizontal: unit(16),
    paddingTop: unit(12),
    paddingBottom: unit(40),
    borderTopLeftRadius: unit(40),
    borderTopRightRadius: unit(40),
  },
  desktop: {
    borderRadius: unit(40),
    marginTop: unit(40),
    marginBottom: unit(40),
  },
  topBar: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  topBarButton: {
    width: unit(50),
  },
  avatar: {
    width: unit(100),
    alignSelf: 'center',
  },
  avatarIcon: {
    position: 'absolute',
    width: unit(34),
    height: unit(34),
    borderRadius: unit(17),
    justifyContent: 'center',
    alignItems: 'center',
    right: 0,
    bottom: 0,
  },
  goalsBoolField: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    marginTop: unit(40),
    alignItems: 'center',
  },
  skillsBoolField: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    marginTop: unit(16),
    alignItems: 'center',
  },
  themeBoolField: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    marginTop: unit(16),
    alignItems: 'center',
    zIndex: 2,
  },
  itemText: {
    marginTop: unit(48),
  },
  itemTextTwo: {
    paddingTop: unit(24),
    marginBottom: unit(16),
  },
  deleteAccountButton: {
    alignSelf: 'center',
  },
  links: {
    marginTop: unit(32),
    alignItems: 'center',
  },
  link: {
    marginTop: unit(16),
  },
});

export default memo(ProfileSettings);
