import request from 'services/api/request';
import type { ResourceVariant, ResultLegacy } from 'app/entities';

type InteractionResult = Record<
  string | number,
  {
    isViewed: boolean;
    isLiked: boolean;
    isDisliked: boolean;
    isBookmarked: boolean;
    isCompleted: boolean;
    isShown: boolean;
  }
>;

export default async (resource: ResourceVariant, resourceIds: number | number[]): Promise<ResultLegacy<InteractionResult>> => {
  if (!resourceIds || (Array.isArray(resourceIds) && resourceIds.length === 0)) {
    return {
      data: {},
      error: null,
    };
  }
  const idsFinal = Array.isArray(resourceIds) ? resourceIds : [resourceIds];

  const query: Record<string, string> = {
    resourceIds: idsFinal.join(','),
  };

  const { data, error } = await request.get<InteractionResult>(`/user/interactions/${resource}`, { query });
  if (error || !data) {
    return {
      data: null,
      error,
    };
  }

  return {
    data,
    error,
  };
};
