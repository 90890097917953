import type { ResultLegacy, TopicType } from 'app/entities';
import request from 'services/api/request';

export default async (pageSize = 50, pageNum = 0): Promise<ResultLegacy<TopicType[]>> => {
  const params = {
    pageSize,
    pageNum,
  };

  const { data, error } = await request.get<TopicType[]>('/skills', {
    query: params,
  });
  if (error || !data) {
    return {
      data: null,
      error,
    };
  }

  return {
    data,
    error,
  };
};
