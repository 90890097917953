import { takeEvery, put, cancel, delay, take } from 'redux-saga/effects';
import { track } from '@amplitude/analytics-browser';

import { getRoute, navigate } from 'navigation/methods';

import { call, select } from 'store/utils/saga/effects';
import * as libraryStore from 'store/nodes/library';
import * as searchStore from 'store/nodes/search';

import { actions } from '../slice';

export const config = {
  action: actions.runPageHandler.type,
  method: takeEvery,
};

export function* func() {
  const route = yield* call(() => getRoute<'Library'>());
  if (!route.isScreen('Library')) {
    yield cancel();
    return;
  }

  const listMeta = yield* select(libraryStore.selectors.listMeta);
  if (listMeta.hasAnyItems === null) {
    yield put(libraryStore.actions.loadMeta());
    yield take(libraryStore.actions.loadMetaDone.type);
  }

  const { interaction: interactionParam } = route.params;

  const filtersMeta = yield* select(libraryStore.selectors.filtersMeta);
  if (!filtersMeta.isLoaded) {
    yield put(libraryStore.actions.loadFilters());
    yield take(libraryStore.actions.loadFiltersDone.type);
    yield delay(10);
  }

  if (route.params.interaction === 'search' && !route.params.text) {
    yield* call(() => navigate('Library', { interaction: 'all' }));
    yield cancel();
  }

  if (route.params.interaction === 'search' && route.params.text) {
    const text = yield* select(searchStore.selectors.text);
    if (!text) {
      yield put(searchStore.actions.setFilterText(route.params.text, true, true));
    }
    yield* call(() => track('Library Search'));
    yield cancel();
  }

  const interactionFilters = yield* select(libraryStore.selectors.panelInteractions);
  yield put(libraryStore.actions.disableFilter(interactionFilters.map((item) => item.id)));
  if (!!interactionParam && route.params.interaction !== 'all') {
    const selectedFilter = interactionFilters.find((item) => item.query.interactionTypes.length === 1 && item.query.interactionTypes.includes(interactionParam.toUpperCase()));
    if (selectedFilter) {
      yield put(libraryStore.actions.enableFilter(selectedFilter.id));
    }
  }

  if (!listMeta.firstPage.isLoaded) {
    yield put(libraryStore.actions.loadList());
  }

  yield* call(() => track('Library'));
}
