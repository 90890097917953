export default {
  Index: '/',
  Ask: '/ask',
  AskCopilot: '/askCopilot',
  Content: '/content/:resourceType/:resourceId',
  CommunityPeers: '/community/peers',
  CommunityCollection: '/community/collection',
  Library: '/library/:interaction',
  'Auth/EmailConfirm': '/auth/email/confirm',
  'Auth/Start': '/welcome',
  'Auth/SignIn': '/auth/sign-in',
  'Auth/SignUp': '/auth/sign-up',
  'Auth/PasswordReset': '/auth/password-reset',
  'Auth/Step1': '/auth/step-1',
  'Auth/TryFree': '/auth/try-free',
  'Auth/WithAppSumo': '/withappsumo',
  Profile: '/profile/:login',
  Author: '/author/:id',
  Playlist: '/playlist/:resourceId',
  Note: '/note/:id',
  Comments: '/:section/:id/comments/',
  NotFound: '*',
};
