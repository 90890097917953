import { takeEvery, put, cancel, type SagaReturnType } from 'redux-saga/effects';

import { navigate } from 'navigation/methods';

import Alert from 'components/Alert';
import dialog from 'components/Dialog';

import { call, select } from 'store/utils/saga/effects';
import { checkUnauthorized } from 'store/utils/credentials';
import * as userStore from 'store/nodes/user';

import network from 'lib/network';
import { actions } from '../slice';

export const config = {
  action: actions.remove.type,
  method: takeEvery,
};

const confirm = (): Promise<boolean> =>
  new Promise((resolve) => {
    dialog.show(
      {
        title: 'Confirmation',
        text: 'Are you sure want to delete\nthis collection?',
      },
      [
        {
          label: 'Cancel',
          variant: 'outlined',
          lightColor: '#929292',
          darkColor: '#77767E',
          handle: (dialogId: string) => {
            dialog.hide(dialogId);
            resolve(false);
          },
        },
        {
          label: 'Yes',
          variant: 'contained',
          lightColor: '#db3327',
          darkColor: '#db3327',
          textLightColor: '#ffffff',
          textDarkColor: '#ffffff',
          handle: (dialogId: string) => {
            dialog.hide(dialogId);
            resolve(true);
          },
        },
      ],
    );
  });

type dataType = {
  data: { success: boolean };
  error: Error | null;
};

const removeCollectionData = async (playlistId: number[]): Promise<dataType> => {
  const ids = !Array.isArray(playlistId) ? [playlistId] : playlistId;
  if (!ids || ids.length === 0) {
    return {
      data: { success: true },
      error: null,
    };
  }
  let error: Error | null = null;
  const responses = await Promise.all(ids.map((id) => network.request(`share/user/playlists/${id}`).delete()));
  responses.forEach((response) => {
    if (response.errors) {
      return;
    }
    error = response.errors;
  });

  return {
    data: { success: !error },
    error,
  };
};

export function* func(action: SagaReturnType<typeof actions.remove>) {
  const { id } = action.payload;
  if (!id) {
    yield cancel();
    return;
  }

  const ids = !Array.isArray(id) ? [id] : id;

  const isConfirmed = yield* call(confirm);
  if (!isConfirmed) {
    yield put(actions.removeDone({ id: ids }));
    yield cancel();
    return;
  }

  const { error, data } = yield* call(() => removeCollectionData(ids));

  yield checkUnauthorized({ data, error });
  if (error) {
    Alert.error('Error delete collection');
    yield put(actions.removeDone({ id: ids }));
    yield cancel();
    return;
  }

  yield* call(() =>
    navigate('Profile', {
      login: 'my',
    }),
  );
  yield put(actions.removeSuccess({ id: ids }));
  yield put(actions.removeDone({ id: ids }));

  const collections = yield* select(userStore.selectors.getCollections('my'));
  if (collections) {
    yield put(
      userStore.actions.setCollections(
        'my',
        collections.filter?.((collection) => !ids.includes(collection.id)),
      ),
    );
  }
  yield put(userStore.actions.loadProfile('my'));
}
