import { takeEvery, put, take, delay, all } from 'redux-saga/effects';
import { track } from '@amplitude/analytics-browser';

import * as api from 'services/api';

import { call } from 'store/utils/saga/effects';
import * as menuStore from 'store/nodes/menu';
import * as authStore from 'store/nodes/auth';
import * as userStore from 'store/nodes/user';
import * as libraryStore from 'store/nodes/library';
import * as searchModalStore from 'store/nodes/searchModal';
import * as subscriptionStore from 'widgets/Subscription/store';

import * as actions from '../actions';

export const config = {
  action: actions.ready.type,
  method: takeEvery,
};

export function* func() {
  yield put(actions.initProtection());
  yield take(actions.initProtectionDone.type);
  yield put(authStore.actions.checkAuth());
  yield put(authStore.actions.startCredentialListening());
  yield take(authStore.actions.checkAuthDone.type);

  const hasSession = api.credentials.hasSession();
  if (hasSession) {
    yield put(userStore.actions.loadProfileOptimistic());
    yield take(userStore.actions.loadProfileDone.type);

    yield put(subscriptionStore.actions.loadState());
    yield put(menuStore.actions.load());
    yield put(libraryStore.actions.loadFilters());
    yield all([take(subscriptionStore.actions.loadStateDone.type), take(menuStore.actions.loadDone.type), take(libraryStore.actions.loadFiltersDone.type)]);

    yield delay(10);
    yield put(searchModalStore.actions.loadFilters());
  }

  yield put(actions.prepared());
  yield* call(() => track('Started'));

  yield delay(10);
  yield put(actions.hideSplashScreen());
}
