import { type SagaReturnType, cancel, delay, put, take, takeEvery } from 'redux-saga/effects';
import { END, eventChannel } from 'redux-saga';

import { call, select } from 'store/utils/saga/effects';

import network from 'lib/network';
import { openWindow } from 'utils';

import Alert from 'components/Alert';

import { actions } from '../slice';
import * as socketActions from '../socketActions';
import * as selectors from '../selectors';

export const config = {
  action: [actions.subscribe.type, socketActions.subscriptionPaymentIntentSucceeded.type],
  method: takeEvery,
};

let windowDescriptor: Window | null = null;
let windowChecker: any = null;
let isProgrammaticClose = false;

function* popupOpen(url: string) {
  windowDescriptor = yield* call(() => openWindow(url, 'Stripe'));
  return yield* call(() => {
    return eventChannel((emitter) => {
      windowChecker = setInterval(() => {
        if (windowDescriptor?.closed) {
          emitter({
            type: 'WINDOW_CLOSED',
            triggerByUser: !isProgrammaticClose,
          });
          emitter(END);
        }
      }, 100);
      return () => {
        if (windowChecker) {
          clearInterval(windowChecker);
        }
      };
    });
  });
}

function* popupClose() {
  if (!windowDescriptor || windowDescriptor.closed) {
    return;
  }
  isProgrammaticClose = true;
  yield* call(() => windowDescriptor?.close());
  yield delay(110);
  isProgrammaticClose = false;
  windowDescriptor = null;
}

export function* func(action: SagaReturnType<typeof actions.subscribe | typeof socketActions.subscriptionPaymentIntentSucceeded>) {
  const currentPlan = yield* select(selectors.currentPlan);

  if (action.type === actions.subscribe.type && !action.payload.planId) {
    yield put(actions.subscribeDone());
    yield cancel();
    return;
  }

  if (action.type === actions.subscribe.type && currentPlan.id === action.payload.planId && currentPlan.isPaused) {
    const { errors, data } = yield* call(() =>
      network
        .request<{
          resumed: boolean;
        }>('/payment/subscription/resume', {
          baseUrl: process.env.PROTECTED_API_URL || '/api',
        })
        .post(),
    );
    if ((errors?.length || 0) > 0 || !data?.resumed) {
      yield* call(() => Alert.error(errors?.[0].message || 'Error subscribe #49'));
      yield put(actions.subscribeDone());
      yield cancel();
      return;
    }
    yield* call(() => Alert.success('Your subscription resumed'));
    yield put(actions.subscribeDone());
    yield cancel();
    return;
  }

  if (action.type === actions.subscribe.type) {
    const { errors, data } = yield* call(() =>
      network
        .request<{ invoiceUrl: string }>(`/payment/subscribe/plans/${action.payload.planId}`, { baseUrl: process.env.PROTECTED_API_URL || '/api' })
        .body({
          trial: action?.payload?.hasTrial === true,
        })
        .post(),
    );

    if ((errors?.length || 0) > 0 || !data) {
      yield* call(() => Alert.error(errors?.[0].message || 'Error subscribe #49'));
      yield put(actions.subscribeDone());
      yield cancel();
      return;
    }
    const windowCloseChannel = yield* call(() => popupOpen(data.invoiceUrl));
    yield take(windowCloseChannel as any);
    yield put(actions.subscribeDone());
    yield cancel();
    return;
  }

  if (action.type === socketActions.subscriptionPaymentIntentSucceeded.type) {
    yield delay(2000);
    yield popupClose();
    yield put(actions.setState({ state: action.payload }));
    yield* call(() => Alert.success('Subscription successful!'));
    yield cancel();
  }
}
