import { type SagaReturnType, takeEvery, cancel, put } from 'redux-saga/effects';

import { call } from 'store/utils/saga/effects';
import { type CollectionPermissionType } from 'app/entities';

import { guard } from 'utils';
import Alert from 'components/Alert';
import network from 'lib/network';

import { actions } from '../slice';

export const config = {
  action: actions.createPermission.type,
  method: takeEvery,
};

export function* func(action: SagaReturnType<typeof actions.createPermission>) {
  const { userId, permissionId, collectionId } = action.payload;

  if (!userId || !permissionId || !collectionId) {
    yield put(actions.createPermissionDone({ userId, permissionId, collectionId }));
    yield cancel();
    return;
  }

  const { data, errors } = yield* call(() =>
    network.request<CollectionPermissionType[]>(`/share/user/playlists/${collectionId}/createPermission`).body({ user_id: userId, permission_id: permissionId }).post(),
  );

  if (errors) {
    Alert.error('Error creating permission');
    yield put(actions.createPermissionDone({ userId, permissionId, collectionId }));
    yield cancel();
    return;
  }
  if (data && guard.isCollectionPermissionType(data)) {
    yield put(actions.setPermissions({ collectionId, permissions: data }));
  }
  yield put(actions.createPermissionDone({ userId, permissionId, collectionId }));
  console.log(data, 'create permission');
}
