import { useMemo } from 'react';

import { useSelector } from 'store/utils/redux/hooks';
import * as noteStore from 'store/nodes/note';

import { guard } from 'utils';
import type { CollectionType, MaterialType, NoteType, RagMaterialType } from 'app/entities';

const useIsIndexing = (data?: MaterialType | CollectionType | NoteType | RagMaterialType | null) => {
  const { isIndexing, isIndexed } = useSelector((state) => {
    if (guard.isNote(data)) {
      return noteStore.selectors.indexState(data?.id)(state);
    }
    if (guard.isCollection(data)) {
      return {
        isIndexed: true,
        isIndexing: false,
      };
    }
    if (guard.isMaterial(data)) {
      return {
        isIndexed: data?.indexed,
        isIndexing: !data?.indexed,
      };
    }
    if (guard.isRagMaterial(data)) {
      return {
        isIndexed: true,
        isIndexing: false,
      };
    }
    return {
      isIndexed: true,
      isIndexing: false,
    };
  });

  return useMemo(
    () => ({
      isIndexing,
      isIndexed,
    }),
    [isIndexing, isIndexed],
  );
};

export default useIsIndexing;
