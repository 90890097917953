import { takeLatest, put, delay, cancel } from 'redux-saga/effects';
import { track } from '@amplitude/analytics-browser';

import * as api from 'services/api';

import { call, select } from 'store/utils/saga/effects';
import * as userStore from 'store/nodes/user';

import Alert from 'components/Alert';

import * as actions from '../actions';

export const config = {
  action: actions.registrationComplete.type,
  method: takeLatest,
};

export function* func() {
  const hasSession = yield* call(() => api.credentials.hasSession());
  if (!hasSession) {
    yield cancel();
    return;
  }

  const userProfile = yield* select(userStore.selectors.getByLogin('my'));
  if (!userProfile) {
    yield cancel();
    return;
  }

  let result = yield* call(() => api.resource.auth.getRegisterCompleteState());
  if (!result.error.hasErrors && !result.data?.isCompleted) {
    yield* call(() => api.resource.auth.registerComplete());
    while (true) {
      result = yield* call(() => api.resource.auth.getRegisterCompleteState());
      if (result.error.hasErrors || !result?.data || result.data?.isCompleted) {
        break;
      }
      yield delay(250);
    }
  }

  if (result?.error.hasErrors || !result?.data) {
    Alert.error(result?.error?.list?.[0].message || 'Server error #5');
    yield put(actions.registrationCompleteDone(true));
    yield cancel();
    return;
  }

  if (result.data?.isCompleted) {
    yield put(
      userStore.actions.setProfile({
        data: {
          ...userProfile,
          isRegisterCompleted: true,
        },
      }),
    );
    yield* call(() => track('Registration:Done', {}, { user_id: userProfile?.id.toString() }));
  }

  yield put(actions.registrationCompleteDone());
}
