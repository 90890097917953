import { type SagaReturnType, takeEvery, cancel, put } from 'redux-saga/effects';

import { request } from 'services/api';
import { stripTags } from 'utils';
import { call } from 'store/utils/saga/effects';
import type { NoteType } from 'app/entities';

import * as actions from '../actions';

export const config = {
  action: actions.create.type,
  method: takeEvery,
};

export function* func(action: SagaReturnType<typeof actions.create>) {
  const { resourceId, resourceType, position, text } = action.payload;

  if (!resourceId) {
    yield put(actions.createDone(false));
    yield cancel();
    return;
  }

  let url = '';
  if (resourceType === 'collection') {
    url = `share/playlists/${resourceId}/materials/${position + 1}/notes`;
  }
  if (resourceType === 'material') {
    console.log('Note::create(for material) => need to implement');
  }

  if (!url) {
    yield put(actions.createDone(false));
    yield cancel();
    return;
  }

  const result = yield* call(() =>
    request.post<NoteType>(url, {
      body: {
        text,
        plain_text: stripTags(text),
      },
    }),
  );

  if (!result.data || result.error) {
    console.log('Error creating note');
    yield put(actions.createDone(false));
    yield cancel();
    return;
  }

  yield put(actions.createDone(true, result.data));
}
