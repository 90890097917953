import { type FC, memo, useCallback } from 'react';
import { useSelector } from 'react-redux';
import { isEqual } from 'lodash';
import { Box, type BoxProps, Button, Tooltip } from '@mui/joy';

import Icon from 'ui/Icon';

import { useDispatcher } from 'store/utils/redux/hooks';
import * as copilotStore from '../store';
import type { Resource } from '../store/types';

type ResetButtonProps = {
  resources: Resource | Resource[];
} & BoxProps;

const ResetButton: FC<ResetButtonProps> = (props) => {
  const { resources, ...rest } = props;

  const dispatcher = useDispatcher();
  const hasItems = useSelector(copilotStore.selectors.hasItems(resources));
  const isAnswerTyping = useSelector(copilotStore.selectors.isAnswerTyping(resources));

  const handleClear = useCallback(() => {
    dispatcher.copilot.clear({
      resources,
    });
  }, [resources]);

  return (
    <Box {...rest}>
      <Tooltip arrow variant="outlined" size="sm" title="Clear context">
        <Button
          size="sm"
          color="neutral"
          disabled={isAnswerTyping || !hasItems}
          variant="soft"
          onClick={handleClear}
          startDecorator={<Icon name="message-plus" fw weight="regular" color={isAnswerTyping || !hasItems ? 'icon' : 'primary'} />}
          sx={{
            height: '2rem',
            fontWeight: 400,
            '--Button-gap': '0.25rem',
          }}
        >
          New chat
        </Button>
      </Tooltip>
    </Box>
  );
};

export default memo(ResetButton, (prevProps, nextProps) => isEqual(prevProps, nextProps));
