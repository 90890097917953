import { type SagaReturnType, put, takeLatest } from 'redux-saga/effects';

import { actions } from '../slice';
import * as socketActions from '../socketActions';

export const config = {
  action: [socketActions.subscriptionPaymentIntentCreated.type, socketActions.customerSubscriptionUpdated.type, socketActions.customerSubscriptionDeleted.type],
  method: takeLatest,
};

export function* func(
  action: SagaReturnType<typeof socketActions.subscriptionPaymentIntentCreated | typeof socketActions.customerSubscriptionUpdated | typeof socketActions.customerSubscriptionDeleted>,
) {
  yield put(actions.setState({ state: action.payload }));
}
