import { createAction } from '@reduxjs/toolkit';
import type { MaterialMetadataItemType } from 'app/entities';

import { subscriptionPaymentIntentCreated, subscriptionPaymentIntentSucceeded, customerSubscriptionUpdated, customerSubscriptionDeleted } from 'widgets/Subscription/store/socketActions';

import { askSearchResult } from 'store/nodes/ask/socketActions';

import { messageInfo, messageError, messageWarning } from 'store/nodes/message/socketActions';

export const map = {
  'socket/close': 'close',
  'socket/ping_pong': 'receivePong',
  [messageInfo.type]: messageInfo,
  [messageError.type]: messageError,
  [messageWarning.type]: messageWarning,
  [askSearchResult.type]: askSearchResult,
  [subscriptionPaymentIntentCreated.type]: subscriptionPaymentIntentCreated,
  [subscriptionPaymentIntentSucceeded.type]: subscriptionPaymentIntentSucceeded,
  [customerSubscriptionUpdated.type]: customerSubscriptionUpdated,
  [customerSubscriptionDeleted.type]: customerSubscriptionDeleted,
} as const;

export const close = createAction('socket/close', (payload: { message: 'close connection' }) => ({
  payload,
}));

export const sendPing = createAction('socket/sendPing', (payload: { message: 'ping' }) => ({
  payload,
}));

export const receivePong = createAction('socket/receivePong', (payload: { message: 'pong' }) => ({
  payload,
}));

export const reconnect = createAction('socket/reconnect');

export const opened = createAction('socket/opened');

export const closed = createAction('socket/closed');

export const error = createAction('socket/error');

export const tick = createAction('socket/tick');

export const summaryPrepared = createAction('socket/summary_prepared', (payload: { request_id: string; material_id: number; summary: string }) => ({
  payload,
}));

export const collectionUpdated = createAction('socket/collection_updated', (payload: { collection_id: number }) => ({
  payload,
}));

export const copilotAnswer = createAction('socket/copilot_answer', (payload: { type: string; request_id: string; chunk: string }) => ({
  payload,
}));

export const collectionAccessChanged = createAction('socket/collectionAccessChanged', (payload: { playlist_id: number }) => ({
  payload,
}));

export const collectionAccessRemoved = createAction('socket/collectionAccessRemoved', (payload: { playlist_id: number }) => ({
  payload,
}));

export const collectionAccessProvided = createAction('socket/collectionAccessProvided', (payload: { playlist_id: number }) => ({
  payload,
}));

export const collectionMaterialsMetadataUpdated = createAction('socket/collectionMaterialsMetadataUpdated', (payload: { playlist_id: number; materials_metadata: MaterialMetadataItemType[] }) => ({
  payload,
}));

export const materialIndexed = createAction('socket/material_indexed', (payload: { material_id: number; request_id: string }) => ({
  payload,
}));
