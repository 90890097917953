import type { ImageType, MaterialType, UserType, BasicUserType, MaterialSourceType } from 'app/entities';
import { capitalize, extensionByContentType, guard } from 'utils';
import * as api from 'services/api';

type NewImageResult =
  | {
      data: string;
      extension: string;
      deleted: false;
    }
  | {
      data: null;
      extension: null;
      deleted: true;
    }
  | undefined;

export const newImage = (image?: ImageType | null): NewImageResult => {
  const isDelete = image === null;
  if (isDelete) {
    return {
      data: null,
      extension: null,
      deleted: true,
    };
  }

  const isNew = guard.isImage(image) && /^data:image\/[a-z]+;base64,/.test(image.url);
  if (isNew) {
    const [, contentType, data] = image.url.match(/^data:(image\/[a-z]+);base64,(.*)/) || [];
    const extension = extensionByContentType(contentType, 'jpg');
    return {
      data,
      extension,
      deleted: false,
    };
  }

  return undefined;
};

export const user = (data: Partial<UserType>, mode: 'full' | 'basic' = 'full'): BasicUserType | UserType => {
  if (mode === 'basic') {
    return {
      id: data?.id || 0,
      login: data?.login || '',
      name: data?.name || '',
      surname: data?.surname || '',
      email: data?.email || null,
      phone: data?.phone || null,
      photo: data?.photo,
    };
  }
  return {
    id: data?.id || 0,
    login: data?.login || '',
    name: data?.name || '',
    surname: data?.surname || '',
    email: data?.email || null,
    phone: data?.phone || null,
    location: data?.location || null,
    photo: data?.photo,
    skills: data?.skills || [],
    jobExperience: (data?.jobExperience || []).map((job) => ({
      id: job.id || 0,
      company: job.company || '',
      position: job.position || '',
      location: job?.location || '',
      startDate: job?.startDate || '1980-01-01',
      endDate: job?.endDate || undefined,
      description: job?.description || undefined,
    })),
    type: data.isLegal ? 'legal' : 'default',
    isLegal: data?.isLegal || false,
    playlistIds: data?.playlistIds || [],
    contentIds: data?.contentIds || [],
    profession: data?.profession || null,
    birthDate: data?.birthDate || null,
    websiteLink: data?.websiteLink || null,
    facebookLink: data?.facebookLink || null,
    instagramLink: data?.instagramLink || null,
    telegramLink: data?.telegramLink || null,
    subscribersCount: data?.subscribersCount || 0,
    subscriptionsCount: data?.subscriptionsCount || 0,
    isMy: api.credentials.sessionId() === data?.id,
    isAppsumo: data?.isAppsumo ?? false,
    isLibraryEmpty: data?.isLibraryEmpty ?? null,
    isSubscribed: api.credentials.sessionId() === data?.id ? null : data?.isSubscribed || false,
    isGoalsDisplayed: data?.isGoalsDisplayed || false,
    isSkillsDisplayed: data?.isSkillsDisplayed || false,
    isConfirmedEmail: data?.isConfirmedEmail || false,
    isRegisterCompleted: data?.isRegisterCompleted || false,
    updatedAt: data?.updatedAt || data?.createdAt || '1970-01-01T00:00:00.000000+00:00',
    createdAt: data?.createdAt || '1970-01-01T00:00:00.000000+00:00',
  };
};

export const materialSource = (data: Partial<MaterialType>): MaterialSourceType | null => {
  if (!!data?.source && data?.source.name) {
    return data.source;
  }
  if (!data.originalUrl || !guard.isLink(data.originalUrl)) {
    return null;
  }
  const domain = data.originalUrl.replace(/^https?:\/\/(?:www\.)?|^(?:www\.)?|\/.*$/g, '').replace(/.*\.(?=.*\..+)/g, '');
  const name =
    {
      'amazonaws.com': 'AWS',
      'stackoverflow.com': 'StackOverflow',
      'blockchain.com': 'Blockchain.com',
      'freecodecamp.org': 'freeCodeCamp',
      'skillbox.ru': 'Skillbox',
      'yandex.ru': 'Yandex',
      'netology.ru': 'Нетология',
      'udemy.com': 'ûdemy',
    }[domain] || domain;
  return {
    id: null,
    name,
  };
};

export const material = (data: Partial<MaterialType>): MaterialType => ({
  id: data?.id || 0,
  internalUrl: `/content/${(data.type || 'article').toLowerCase()}/${data.id}`,
  type: capitalize(data.type || 'article'),
  title: data?.title,
  description: data?.description,
  authors: data?.authors,
  originalUrl: data?.originalUrl,
  image: data?.image,
  sourceId: data?.sourceId,
  source: materialSource(data),
  tags: data?.tags,
  sectionId: data?.sectionId,
  duration: data?.duration,
  level: data?.level,
  summary: data?.summary,
  highlight: data?.highlight,
  note: data?.note,
  myNotes: data?.myNotes || [],
  remarks: data?.remarks || [],
  indexed: data?.indexed || false,
  isViewed: data?.isViewed || false,
  isDisliked: data?.isDisliked || false,
  isLiked: data?.isLiked || false,
  isBookmarked: data?.isBookmarked || false,
  isCompleted: data?.isCompleted || false,
  isShown: data?.isShown || false,
  creator: data?.creator || null,
  publishedAt: data?.publishedAt,
  createdAt: data?.createdAt,
  updatedAt: data?.updatedAt,
  deletedAt: data?.deletedAt,
});
