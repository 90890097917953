import { takeEvery, put, cancel, type SagaReturnType, take } from 'redux-saga/effects';

import { call, select } from 'store/utils/saga/effects';
import * as smartNoteStore from 'store/nodes/smartNote';
import * as materialStore from 'store/nodes/content';

import * as api from 'services/api';
import { actions } from '../slice';
import * as selectors from '../selectors';

export const config = {
  action: [actions.addNoteItem.type, actions.addMaterialItem.type],
  method: takeEvery,
};

export function* func(action: SagaReturnType<typeof actions.addNoteItem> | SagaReturnType<typeof actions.addMaterialItem>) {
  const { collectionId, itemType, options } = action.payload;

  if (!collectionId || !itemType) {
    yield put(actions.addItemDone({ success: false }));
    yield cancel();
    return;
  }

  const collection = yield* select(selectors.dataById(collectionId));
  if (!collection) {
    yield put(actions.addItemDone({ success: false }));
    yield cancel();
    return;
  }

  let newPosition = 0;
  if (!!options && 'after' in options) {
    const index = (collection.materialsMetadata || []).findIndex((item) => item.type === options.after?.type && item.id === options.after.id);

    if (index > -1) {
      newPosition = index + 1;
    }
  }

  const isAppend = options?.append;
  const isPrepend = (!options || !('after' in options)) && !isAppend;

  if (isPrepend) {
    newPosition = 0;
  }

  if (isAppend) {
    newPosition = collection.materialsMetadata.length;
  }

  if (action.type === actions.addNoteItem.type) {
    const { text } = action.payload;
    yield put(smartNoteStore.actions.create('collection', collectionId, text, newPosition));
    const { payload: createResult }: SagaReturnType<typeof smartNoteStore.actions.createDone> = yield take(smartNoteStore.actions.createDone.type);
    if (!createResult.success || !createResult.data) {
      yield put(actions.addItemDone({ success: false }));
      yield cancel();
      return;
    }
    yield put(
      actions.addItemDone({
        success: true,
        data: { collectionId, position: newPosition, item: createResult.data },
      }),
    );
    yield put(actions.updateInBackground({ id: collectionId }));
    yield cancel();
    return;
  }

  if (action.type === actions.addMaterialItem.type) {
    const { data } = action.payload;

    const { data: addedData, error: addedError } = yield* call(() => api.resource.myContent.add(data));
    if (addedError || !addedData) {
      yield put(actions.addItemDone({ success: false }));
      yield cancel();
      return;
    }

    const isAlreadyInside = collection.materialsMetadata.some((item) => item.id === addedData.id && item.type === 'material');
    if (isAlreadyInside) {
      yield put(actions.addItemDone({ success: false }));
      yield cancel();
      return;
    }
    yield put(
      actions.addItemDone({
        success: true,
        data: { collectionId, position: newPosition, item: addedData },
      }),
    );
    yield put(materialStore.actions.loadById(addedData.id));
    // @todo возможно добавить в мой контент динамически
    yield put(actions.updateInBackground({ id: collectionId }));
    yield cancel();
    return;
  }

  yield put(actions.addItemDone({ success: false }));
}
