import { type SagaReturnType, takeEvery, cancel, put } from 'redux-saga/effects';

import type { NoteType } from 'app/entities';
import { stripTags } from 'utils';

import { call, select } from 'store/utils/saga/effects';
import * as noteStore from 'store/nodes/note';

import Alert from 'components/Alert';
import network from 'lib/network';
import * as materialStore from 'store/nodes/content';

import { actions, type ResourceType } from '../slice';
import * as utils from '../utils';

export const config = {
  action: actions.update.type,
  method: takeEvery,
};

const getUrl = (id: number, relation?: { resourceType: ResourceType; resourceId: number }) => {
  if (!relation || !relation?.resourceType) {
    return `/notes/${id}`;
  }
  if (relation.resourceType === 'material') {
    return `/content/${relation.resourceId}/note/${id}`;
  }
  if (relation.resourceType === 'collection') {
    return `/playlists/${relation.resourceId}/note/${id}`;
  }
  return null;
};

export function* func(action: SagaReturnType<typeof actions.update>) {
  const { id, html, isPrivate } = action.payload;

  const relation = yield* select(noteStore.selectors.resourceByNoteId(id));

  const url = yield* call(() => getUrl(id, relation));
  if (!url) {
    yield put(actions.updateDone({ id }));
    yield cancel();
    return;
  }

  const { data, errors } = yield* call(() =>
    network
      .request<NoteType>(url)
      .body({
        text: html,
        plain_text: stripTags(html),
        ...(typeof isPrivate !== 'undefined' ? { private: isPrivate } : undefined),
      })
      .post(),
  );
  if (!data || errors) {
    yield put(actions.updateDone({ id }));
    Alert.error('Error updating note');
    yield cancel();
    return;
  }

  const item = {
    data: yield* utils.prepareNote(data),
    ...(relation && {
      relation: {
        resourceType: relation.resourceType,
        resourceId: relation.resourceId,
      },
    }),
  };

  yield put(actions.setItem(item));
  yield put(actions.updateDone({ id }));

  if (relation?.resourceId) {
    yield put(materialStore.actions.loadById(relation.resourceId));
  }
}
