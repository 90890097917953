import { memo, useCallback, useMemo, useEffect } from 'react';
import { StyleSheet } from 'react-native';
import { type DefaultRootState, useSelector } from 'react-redux';
import { DateTime } from 'luxon';

import { useFocusEffect, useRoute } from 'navigation/hooks';
import { useListData, useListLayout } from 'hooks';
import { unit } from 'utils';

import { useDispatcher } from 'store/utils/redux/hooks';
import * as libraryStore from 'store/nodes/library';

import { View } from 'components/Themed';
import AdaptivePage from 'components/AdaptivePage';
import List from 'components/List';
import { useScrollMethods } from 'components/Scroller';

import * as userStore from 'store/nodes/user';

import Card from 'widgets/Card';

import EmptyMessage from '../../ui/StateMessage';
import CardPlus from './ui/CardPlus';
import TopBarDesktop from './ui/TopBarDesktop';
import TopBarMobile from './ui/TopBarMobile';

const Library = () => {
  const dispatcher = useDispatcher();
  const { setScrollValue, getScrollValue, scrollTo } = useScrollMethods();
  const { listLayout } = useListLayout('library.list.mode');
  const route = useRoute();

  const list = useSelector((state: DefaultRootState) => {
    return libraryStore.selectors.list(state);
  });
  const listMeta = useSelector((state: DefaultRootState) => {
    return libraryStore.selectors.listMeta(state);
  });

  const listData = useListData(list.sequence, listMeta, 12);

  const userProfile = useSelector(userStore.selectors.getMy);

  const isLegalBokInteraction = userProfile?.type === 'legal' && (route.params as Record<string, string>)?.interaction === 'legal bok';

  useEffect(() => {
    if (listMeta.firstPage.isLoading === true) {
      setScrollValue('Library', 0);
      scrollTo(0);
    }
  }, [listMeta.firstPage.isLoading]);

  useFocusEffect(
    useCallback(() => {
      const { top } = getScrollValue('Library');
      setTimeout(() => {
        // @todo реализовать совместимую со списками версию (для devices)
        scrollTo(top);
      }, 10);
    }, []),
  );

  const handleRefresh = useCallback(() => {
    dispatcher.library.loadList();
  }, []);

  const handleNextPage = useCallback(() => {
    if (!listMeta.isConsistent) {
      return;
    }
    dispatcher.library.loadNextPage();
  }, [listMeta.isConsistent]);

  const handleScroll = useCallback((event: any) => {
    // @todo реализовать совместимую со списками версию (для devices)
    const { y } = event.nativeEvent.contentOffset;
    if (window.location.pathname.includes('/library')) {
      setScrollValue('Library', y);
    }
  }, []);

  const isTwoWeekAgo = useMemo(() => DateTime.now().diff(DateTime.fromISO(userProfile?.createdAt as string), ['weeks']).weeks > 2, [userProfile?.createdAt]);

  const renderItem = useCallback(
    ({ item, i }: any) => {
      const isTemplateNeeded = i === 0;
      return (
        <>
          {isTemplateNeeded && !isTwoWeekAgo && (
            <CardPlus
              sx={{
                mx: 1,
                mb: 2,
              }}
            />
          )}
          <Card
            id={item.id}
            type={item.type}
            hasDescription={listLayout.isRow}
            hasSummary
            hasHighlight
            hasTags
            hasRemove
            hasQueue={false}
            hasLink
            hasEdit
            hasAuthor
            hasToCollection
            hasIndexed
            sx={{
              mx: 1,
              mb: 2,
            }}
          />
        </>
      );
    },
    [listLayout.isRow, isLegalBokInteraction, userProfile?.type, listData.sequence],
  );

  const pagePaddingHorizontalScheme = useMemo(
    () => ({
      320: 16,
    }),
    [],
  );

  return (
    <AdaptivePage desktopHeaderLeftPanel="search" bodyMaxWidth={1280} paddingHorizontalScheme={pagePaddingHorizontalScheme}>
      <TopBarDesktop />
      <TopBarMobile />
      <EmptyMessage />
      <List
        type="masonry"
        componentStyle={styles.listComponent}
        contentContainerStyle={styles.listContentContainer}
        scrollEmitter="web-page"
        data={listData.sequence}
        keyExtractor={(item) => `${item.id}`}
        renderItem={renderItem}
        columnsScheme={listLayout.isRow ? '320:1' : '320:1;528:2;672:3;960:4'}
        // @ts-ignore
        showsVerticalScrollIndicator={false}
        hasNextPage={(listMeta.firstPage.isLoading || listMeta.nextPage.isLoading || list.sequence.length > 0) && list.paginationInfo.hasNext}
        onRefresh={handleRefresh}
        onScroll={handleScroll}
        onEndReached={handleNextPage}
        ListFooterComponent={<View style={{ height: unit(108) }} />}
      />
    </AdaptivePage>
  );
};

const styles = StyleSheet.create({
  bar: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    marginTop: unit(15),
  },
  tags: {
    marginTop: unit(15),
  },
  listComponent: {
    marginHorizontal: unit(-10),
  },
  listContentContainer: {
    alignSelf: 'stretch',
  },
  listItemSearch: {
    marginBottom: unit(16),
  },
  listItemRegular: {
    marginHorizontal: unit(10),
    marginBottom: unit(20),
  },
  searchInput: {
    maxHeight: unit(14),
    width: unit(304),
  },
  panelTabSwitcher: {
    marginLeft: unit(24),
    marginRight: unit(24),
    borderRadius: unit(24),
    height: unit(36),
  },
  tabSwitcher: {
    left: unit(24),
    right: unit(24),
  },
  collection: {
    paddingBottom: unit(80),
    maxWidth: unit(1280),
    width: '100%',
    alignSelf: 'center',
  },
  loading: {
    width: '100%',
    height: '100%',
    justifyContent: 'center',
    alignItems: 'center',
  },
  header: {
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    zIndex: 2,
  },
  blockHeaderBar: {
    flexDirection: 'row',
    alignItems: 'center',
    paddingHorizontal: unit(16),
  },
  blockTitle: {
    flex: 1,
  },
  itemToolbar: {
    flexDirection: 'row',
    justifyContent: 'flex-end',
    flex: 1,
  },
  blockToolbarSwitcher: {
    position: 'relative',
    paddingVertical: unit(8),
    paddingHorizontal: unit(8),
    marginVertical: unit(-8),
    marginHorizontal: unit(-8),
  },
  baseScroll: {
    height: '100%',
  },
  block: {
    marginTop: unit(12),
    marginBottom: unit(24),
  },
  item: {
    width: unit(160),
    marginHorizontal: unit(6),
    marginBottom: unit(12),
  },
});

export default memo(Library);
