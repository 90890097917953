import { memo, useCallback, useEffect, useState } from 'react';
import { DialogTitle, FormControl, FormLabel, Textarea, Modal, ModalClose, ModalDialog, Option, Select, Stack, Button, Box, Switch, DialogContent, DialogActions } from '@mui/joy';
import { useDispatcher } from 'store/utils/redux/hooks';

import { useSelector } from 'react-redux';
import * as modalStore from 'widgets/modals/store';
import * as contentStore from 'store/nodes/content';
import SelectInput from 'components/SelectInput';
import { type MaterialType } from 'app/entities';

const EditMaterial = () => {
  const dispatcher = useDispatcher();
  const { isOpen, params } = useSelector(modalStore.selectors.state('EditMaterial'));

  const originalData = useSelector(contentStore.selectors.dataById(Number(params?.id)));

  const contentTypeOptions = useSelector(contentStore.selectors.filterOptions(['all']));

  const [formData, setFormData] = useState<MaterialType | null>(originalData);
  useEffect(() => {
    if (originalData) {
      setFormData({
        ...originalData,
      });
    }
  }, [originalData]);

  const handleChange = useCallback((event: { target: { name: string; value: string | boolean | null | string[] } }) => {
    const { name, value } = event.target;
    setFormData((prevFormData) => ({
      ...(prevFormData as MaterialType),
      [name]: value,
    }));
  }, []);

  const handleModalClose = useCallback(async () => {
    dispatcher.modal.close('EditMaterial');
  }, []);

  const handleSubmit = useCallback(() => {
    if (!formData) {
      return;
    }
    dispatcher.myContent.editMaterial(formData, { showSuccessToast: true });
    handleModalClose();
  }, [formData, dispatcher, handleModalClose]);

  if (!formData) {
    return null;
  }

  return (
    <Modal open={isOpen} onClose={handleModalClose}>
      <ModalDialog
        variant="soft"
        size="lg"
        sx={{
          width: 500,
          '--ModalClose-radius': '1.125em',
        }}
      >
        <ModalClose onClick={handleModalClose} />
        <DialogTitle sx={{ mt: -1 }}>Edit material</DialogTitle>
        <DialogContent>
          <Stack spacing={2}>
            <FormControl>
              <FormLabel>Content type</FormLabel>
              <Select
                variant="plain"
                value={formData?.type?.toLowerCase()}
                name="type"
                onChange={(_, value) => {
                  const event = {
                    target: {
                      name: 'type',
                      value,
                    },
                  };
                  handleChange(event);
                }}
              >
                {contentTypeOptions?.map((item) => <Option value={item.value}>{item.label}</Option>)}
              </Select>
            </FormControl>
            <FormControl>
              <FormLabel>Title</FormLabel>
              <Textarea variant="plain" maxRows={3} value={formData?.title} name="title" onChange={handleChange} />
            </FormControl>
            <FormControl>
              <FormLabel>Description</FormLabel>
              <Textarea variant="plain" maxRows={5} value={formData?.description} name="description" onChange={handleChange} />
            </FormControl>
            <FormControl>
              <FormLabel>Tags</FormLabel>
              <Box sx={{ borderWidth: '1px', borderColor: '#FFFFFF' }}>
                <SelectInput
                  isMulti
                  optionsUrl="/skills/search?pageNum=0&pageSize=50&q=[TEXT]"
                  value={formData?.tags || null}
                  onChange={(selectedOptions) => {
                    const event = {
                      target: {
                        name: 'tags',
                        value: selectedOptions,
                      },
                    };
                    handleChange(event);
                  }}
                  colors={{
                    outline: { light: '#FFFFFF', dark: '#000000' },
                    controlBackground: { light: '#FFFFFF', dark: '#000000' },
                    menuBackground: { light: '#FFFFFF', dark: '#000000' },
                  }}
                />
              </Box>
            </FormControl>
            <FormControl orientation="horizontal">
              <FormLabel>{formData?.private ? 'Private content' : 'Not private content'}</FormLabel>
              <Switch
                sx={{ alignSelf: 'flex-start' }}
                size="md"
                checked={formData?.private}
                onChange={(e) => {
                  const event = {
                    target: {
                      name: 'private',
                      value: e.target.checked,
                    },
                  };
                  handleChange(event);
                }}
              />
            </FormControl>
          </Stack>
        </DialogContent>
        <DialogActions sx={{ pt: 0 }}>
          <Box display="flex" justifyContent="center" flex={1}>
            <Button disabled={JSON.stringify(originalData) === JSON.stringify(formData)} onClick={handleSubmit}>
              Save
            </Button>
          </Box>
        </DialogActions>
      </ModalDialog>
    </Modal>
  );
};

export default memo(EditMaterial);
