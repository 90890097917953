import { memo, useCallback } from 'react';
import { Box, CardContent, Tooltip, Typography } from '@mui/joy';
import Icon from 'ui/Icon';
import { type AuthorType, type CollectionType, type ImageType, type UserType } from 'app/entities';

import CompositePoster from 'components/CompositePoster';

import useAuthor from '../model/useAuthor';

import DescriptionBlock from '../ui/DescriptionBlock';
import AuthorBlock from '../ui/AuthorBlock';

type CollectionProps = {
  data?: CollectionType;
  posterSources?: ImageType[] | string[];
  handleAuthorPress?: (value: UserType | AuthorType | null) => void;
  hasDescription?: boolean;
  hasSummary?: boolean;
  hasAuthor?: boolean;
  descriptionLineLimit?: number;
};

const Collection = (props: CollectionProps) => {
  const { data, posterSources, handleAuthorPress, descriptionLineLimit, hasDescription, hasSummary, hasAuthor } = props;

  const authorData = useAuthor(data?.user);

  const handlePress = useCallback(() => {
    if (!authorData) {
      return;
    }
    handleAuthorPress?.(authorData);
  }, [authorData]);

  return (
    <>
      <Box
        sx={{
          '@container (width > 400px )': {
            display: 'none',
          },
        }}
      >
        {posterSources && posterSources.length > 0 && <CompositePoster sources={posterSources} radius={8} aspectRatio={4 / 3} />}
      </Box>
      <CardContent>
        <Box display="flex" flexDirection="row" justifyContent="space-between">
          <Box
            display="flex"
            flexDirection="column"
            gap={1}
            sx={{
              '@container (width < 400px )': {
                gap: 1.5,
              },
            }}
          >
            {data?.title && (
              <Typography
                fontSize={17}
                sx={{
                  display: '-webkit-box',
                  WebkitLineClamp: 3,
                  WebkitBoxOrient: 'vertical',
                  overflow: 'hidden',
                  textOverflow: 'ellipsis',
                }}
                fontWeight={600}
              >
                {data.isPrivate && (
                  <Tooltip placement="top" arrow disableInteractive variant="outlined" size="sm" title="This collection is visible only to you.">
                    <Typography component="span" fontSize={16}>
                      <Icon size="sm" marginRight={1} weight="solid" color="var(--joy-palette-primary-solidDisabledColor)" name="eye-slash" />
                    </Typography>
                  </Tooltip>
                )}
                {data?.title?.trim?.()}
              </Typography>
            )}
            {hasDescription && data?.description && <DescriptionBlock ellipsizeMode="tail" numberOfLines={descriptionLineLimit || 20} text={data.description} />}
            {/* {hasSummary && data?.gptSummary && ( */}
            {/*   <Summary isHover={isHover} text={data?.gptSummary || data.summary} /> */}
            {/* )} */}
            {hasAuthor && !!authorData && <AuthorBlock onPress={handlePress} createData={data?.createdAt} data={authorData} />}
          </Box>
          {posterSources && posterSources.length > 0 && (
            <Box
              sx={{
                ml: 1,
                '@container (width < 400px )': {
                  display: 'none',
                },
              }}
            >
              <CompositePoster width={120} sources={posterSources} radius={8} aspectRatio={16 / 9} />
            </Box>
          )}
        </Box>
      </CardContent>
    </>
  );
};

export default memo(Collection);
