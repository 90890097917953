import type { ResultLegacy, MaterialType } from 'app/entities';
import request from 'services/api/request';
import interaction from 'services/api/interaction/route';

export default async (): Promise<ResultLegacy<MaterialType>> => {
  const { data, error } = await request.get<MaterialType>('/content/watching');

  if (error || !data) {
    return {
      data: null,
      error,
    };
  }

  const [interactionsMap] = await Promise.all([interaction.items('content', [data.id])]);

  if (interactionsMap.error || !interactionsMap.data) {
    return {
      data: null,
      error: interactionsMap.error,
    };
  }

  const result = {
    ...data,
    ...interactionsMap?.data?.[data?.id],
  };

  return {
    data: result,
    error,
  };
};
