import { type SagaReturnType, takeEvery, cancel, put, take, delay } from 'redux-saga/effects';

import { call, select } from 'store/utils/saga/effects';
import { navigate } from 'navigation/methods';

import { actions } from '../slice';
import * as selectors from '../selectors';

export const config = {
  action: [actions.tryFree.type],
  method: takeEvery,
};

export function* func(action: SagaReturnType<typeof actions.tryFree>) {
  let currentPlan = yield* select(selectors.currentPlan);
  if (currentPlan.hasPaymentMethod) {
    yield put(actions.subscribe({ planId: '1', hasTrial: true }));
    yield take(actions.subscribeDone.type);
    yield put(actions.loadState());
    yield take(actions.loadStateDone.type);
    yield* call(() => navigate('Ask'));
    yield put(actions.tryFreeDone());
    yield cancel();
    return;
  }

  yield put(actions.openBillingPortal({ hasTrial: true, openMode: 'popup' }));
  yield take(actions.closeBillingPortal.type);
  yield delay(10);
  yield put(actions.loadState());
  yield take(actions.loadStateDone.type);
  yield delay(10);

  currentPlan = yield* select(selectors.currentPlan);
  if (currentPlan.hasPaymentMethod) {
    yield put(actions.subscribe({ planId: '1', hasTrial: true }));
    yield take(actions.subscribeDone.type);
    yield put(actions.loadState());
    yield take(actions.loadStateDone.type);
    yield* call(() => navigate('Ask'));
  }
  yield put(actions.tryFreeDone());
}
