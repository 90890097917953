import { type SagaReturnType, takeEvery, put, cancel, delay, take } from 'redux-saga/effects';

import { navigate, getRoute } from 'navigation/methods';

import * as api from 'services/api';

import { call, select } from 'store/utils/saga/effects';
import * as appStore from 'store/nodes/app';
import * as contentStore from 'store/nodes/content';
import * as userStore from 'store/nodes/user';

import * as selectors from 'widgets/Subscription/store/selectors';
import { actions } from '../slice';

export const config = {
  action: [actions.locationChange.type, actions.route.type],
  method: takeEvery,
};

function* withSession() {
  const route = yield* call(() => getRoute());
  const user = yield* select(userStore.selectors.getByLogin('my'));
  const currentPlan = yield* select(selectors.currentPlan);

  if (user && !user.isConfirmedEmail) {
    if (!route.isScreen('Auth/EmailConfirm')) {
      yield* call(() => navigate('Auth/EmailConfirm', { replace: true }));
    }
    return;
  }

  if (user && !user.name) {
    if (!route.isScreen('Auth/Step1')) {
      yield* call(() => navigate('Auth/Step1', { replace: true }));
    }
    return;
  }

  if (!route.isScreen('Auth/TryFree') && user && currentPlan.id === '0' && !localStorage.getItem('appsumo')) {
    const isTrialAsked = yield* call(() => localStorage.getItem(`isTrialAsked#${user?.id}`) === 'true');
    if (!isTrialAsked) {
      yield put(actions.redirectBefore({ route }));
      yield* call(() => navigate('Auth/TryFree', { replace: true }));
      yield put(actions.redirectAfter({ route: getRoute() }));
      return;
    }
  }

  if (route.isScreen('Auth/TryFree') && currentPlan.id !== '-1' && currentPlan.id !== '0') {
    yield put(actions.redirectBefore({ route }));
    yield* call(() => navigate('Ask', { replace: true }));
    yield put(actions.redirectAfter({ route: getRoute() }));
    return;
  }

  if (
    route.isScreen('Index') ||
    route.isScreen('Auth/Start') ||
    route.isScreen('Auth/SignIn') ||
    route.isScreen('Auth/SignUp') ||
    route.isScreen('Auth/WithAppSumo') ||
    route.isScreen('Auth/Step1') ||
    route.isScreen('Auth/EmailConfirm')
  ) {
    yield put(actions.redirectBefore({ route }));
    yield* call(() => navigate('Ask', { replace: true }));
    yield put(actions.redirectAfter({ route: getRoute() }));
  }
}

function* withoutSession() {
  const route = yield* call(getRoute);

  if (route.isScreen('Index')) {
    yield put(actions.redirectBefore({ route }));
    yield* call(() => navigate('Auth/Start', { replace: true }));
    yield put(actions.redirectAfter({ route: getRoute() }));
    return;
  }

  if (
    route.isScreen('Content') ||
    route.isScreen('Playlist') ||
    route.isScreen('Auth/Start') ||
    route.isScreen('Auth/SignIn') ||
    route.isScreen('Auth/SignUp') ||
    route.isScreen('Auth/PasswordReset') ||
    route.isScreen('Auth/WithAppSumo')
  ) {
    return;
  }
  yield put(actions.redirectBefore({ route }));
  yield* call(() => navigate('Auth/Start', { replace: true }));
  yield put(actions.redirectAfter({ route: getRoute() }));
}

function* routeDone(action: SagaReturnType<typeof actions.locationChange | typeof actions.route>) {
  if (action.type === actions.route.type) {
    yield put(actions.routeDone());
  }
}

export function* func(action: SagaReturnType<typeof actions.locationChange | typeof actions.route>) {
  const isAppPrepared = yield* select((state) => state.app.prepared);
  if (!isAppPrepared) {
    yield take(appStore.actions.prepared.type);
  }

  const route = getRoute();
  document.body.setAttribute('data-rout-name', route.name);

  const hasSession = yield* call(() => api.credentials.hasSession());
  if (!hasSession) {
    yield withoutSession();
    yield delay(10);
    yield put(actions.runPageHandler());
    yield routeDone(action);
    yield cancel();
    return;
  }

  yield withSession();
  yield delay(10);
  yield put(actions.runPageHandler());

  // @todo Другие вызовы. Вынести в отдельную сагу.
  const filterType = yield* select(contentStore.selectors.filter<string>('type', 'All'));
  if (!filterType.meta.isLoaded) {
    yield put(contentStore.actions.loadFilters());
    yield take(contentStore.actions.loadFiltersDone.type);
  }

  yield routeDone(action);
}
