import { useMemo } from 'react';

import type { MaterialType, CollectionType, NoteType, RagMaterialType } from 'app/entities';
import { guard } from 'utils';

export default (data: MaterialType | CollectionType | RagMaterialType | NoteType | null) =>
  useMemo(() => {
    const { id = null } = data || {};
    let type: 'material' | 'collection' | 'note' | null = null;
    let legacy: 'content' | 'playlist' | 'note' | null = null;

    if (guard.isMaterial(data)) {
      type = 'material';
      legacy = 'content';
    }
    if (guard.isCollection(data)) {
      type = 'collection';
      legacy = 'playlist';
    }
    if (guard.isNote(data)) {
      type = 'note';
      legacy = 'note';
    }
    if (guard.isRagMaterial(data)) {
      return null;
    }

    if (!id || !type || !legacy) {
      return null;
    }

    return {
      id: Number(id),
      type,
      legacy: {
        id: Number(id),
        type: legacy,
      },
    };
  }, [data]);
