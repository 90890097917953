import { type CSSProperties, type ReactNode, type PropsWithChildren, memo, useCallback, useEffect, useState, useMemo, useRef } from 'react';
import { type StyleProp, type ViewStyle, type ImageStyle, Platform, StyleSheet, Image, Animated } from 'react-native';

import type { ImageType } from 'app/entities';

import { useThemeColor } from 'hooks';
import { unit, updateImageSize } from 'utils';

import { View } from 'components/Themed';
import Icon from 'ui/Icon';

const defaultProps = {
  radius: 5 as number | false,
  hasWireframe: true,
  size: 'original' as 'small' | 'medium' | 'large' | 'original',
};

type PictureProps = {
  style?: StyleProp<ViewStyle>;
  width?: number;
  height?: number;
  source?: ImageType | string | null;
  radius?: number | false;
  radiusLeft?: number | false;
  radiusRight?: number | false;
  aspectRatio?: number | 'none';
  hasWireframe?: boolean;
  hasBackground?: boolean;
  size?: 'small' | 'medium' | 'large' | 'original';
  HoverComponent?: ReactNode;
  onLoad?: () => void;
  onError?: () => void;
  onPrepared?: () => void;
} & typeof defaultProps;

const Picture = (props: PropsWithChildren<PictureProps>) => {
  const { style, width, height, children, source, radius, radiusLeft, radiusRight, aspectRatio, hasWireframe, hasBackground, size, HoverComponent, onLoad, onError, onPrepared } = props;

  const [isHovered, setHovered] = useState(false);
  const hoverComponentOpacity = useRef(new Animated.Value(0)).current;

  const backgroundColor = useThemeColor({ light: '#ffffff', dark: '#1b1b1b' });

  const [isLoaded, setLoaded] = useState<boolean>(false);
  const [isEmpty, setEmpty] = useState<boolean>(false);

  const meta = useMemo(() => {
    const prepared = updateImageSize(source, size);
    if (!prepared) {
      return {
        url: null,
        width: null,
        height: null,
        ratio: null,
      };
    }
    return {
      url: prepared.url,
      width: width || prepared?.width || null,
      height: height || prepared?.height || null,
      ratio: prepared?.width && prepared?.height ? prepared.width / prepared.height : null,
    };
  }, [source, size, width, height]);

  const handleLoadEnd = useCallback(() => {
    setLoaded(true);
    setEmpty(false);
    onLoad?.();
    onPrepared?.();
  }, [onLoad, onPrepared]);

  const handleLoadError = useCallback(() => {
    setLoaded(false);
    setEmpty(true);
    onError?.();
    onPrepared?.();
  }, [onError, onPrepared]);

  const handleHoverEnter = useCallback(() => {
    setHovered(true);
  }, []);

  const handleHoverLeave = useCallback(() => {
    setHovered(false);
  }, []);

  useEffect(() => {
    if (!meta.url) {
      handleLoadError();
      return;
    }
    setLoaded(false);
    setEmpty(false);
  }, [handleLoadError, meta.url]);

  const containerStyle = useMemo(() => {
    const result: StyleProp<ViewStyle> = {
      position: 'relative',
    };
    if (aspectRatio !== 'none' && meta.ratio) {
      result.aspectRatio = meta.ratio;
    }
    if (aspectRatio !== 'none' && !meta.ratio && meta.width && meta.height) {
      result.aspectRatio = meta.width / meta.height;
    }
    if (aspectRatio !== 'none' && aspectRatio) {
      result.aspectRatio = aspectRatio;
    }
    if (width) {
      result.width = unit(width);
    }
    if (height) {
      result.height = unit(height);
    }
    if (typeof radius !== 'undefined' && typeof radius !== 'boolean') {
      result.borderRadius = unit(radius);
    }
    if (typeof radiusLeft !== 'undefined' && typeof radiusLeft !== 'boolean') {
      result.borderBottomLeftRadius = unit(radiusLeft);
      result.borderTopLeftRadius = unit(radiusLeft);
    }
    if (typeof radiusRight !== 'undefined' && typeof radiusRight !== 'boolean') {
      result.borderBottomRightRadius = unit(radiusRight);
      result.borderTopRightRadius = unit(radiusRight);
    }
    return [{ width: '100%' }, style, styles.Poster, result];
  }, [aspectRatio, width, height, radius, style, radiusLeft, radiusRight]);

  const emptyStyleFinal = useMemo(
    (): StyleProp<ViewStyle> => [
      styles.empty,
      {
        borderRadius: typeof radius !== 'undefined' && typeof radius !== 'boolean' ? unit(radius) : undefined,
        borderBottomLeftRadius: typeof radiusLeft !== 'undefined' && typeof radiusLeft !== 'boolean' ? radiusLeft : undefined,
        borderTopLeftRadius: typeof radiusLeft !== 'undefined' && typeof radiusLeft !== 'boolean' ? radiusLeft : undefined,
        borderBottomRightRadius: typeof radiusRight !== 'undefined' && typeof radiusRight !== 'boolean' ? radiusRight : undefined,
        borderTopRightRadius: typeof radiusRight !== 'undefined' && typeof radiusRight !== 'boolean' ? radiusRight : undefined,
      },
    ],
    [radius, radiusLeft, radiusRight],
  );

  const loadingStyleFinal = useMemo(
    (): StyleProp<ViewStyle> => [
      styles.loading,
      {
        borderRadius: typeof radius !== 'undefined' && typeof radius !== 'boolean' ? unit(radius) : undefined,
        borderBottomLeftRadius: typeof radiusLeft !== 'undefined' && typeof radiusLeft !== 'boolean' ? radiusLeft : undefined,
        borderTopLeftRadius: typeof radiusLeft !== 'undefined' && typeof radiusLeft !== 'boolean' ? radiusLeft : undefined,
        borderBottomRightRadius: typeof radiusRight !== 'undefined' && typeof radiusRight !== 'boolean' ? radiusRight : undefined,
        borderTopRightRadius: typeof radiusRight !== 'undefined' && typeof radiusRight !== 'boolean' ? radiusRight : undefined,
      },
    ],
    [radius, radiusLeft, radiusRight],
  );

  const imageStyleFinal = useMemo(() => {
    if (Platform.OS !== 'web') {
      return {
        width: '100%',
        maxHeight: '100%',
        borderRadius: typeof radius !== 'undefined' && typeof radius !== 'boolean' ? unit(radius) : undefined,
        borderBottomLeftRadius: typeof radiusLeft !== 'undefined' && typeof radiusLeft !== 'boolean' ? radiusLeft : undefined,
        borderTopLeftRadius: typeof radiusLeft !== 'undefined' && typeof radiusLeft !== 'boolean' ? radiusLeft : undefined,
        borderBottomRightRadius: typeof radiusRight !== 'undefined' && typeof radiusRight !== 'boolean' ? radiusRight : undefined,
        borderTopRightRadius: typeof radiusRight !== 'undefined' && typeof radiusRight !== 'boolean' ? radiusRight : undefined,
        backgroundColor: hasBackground ? backgroundColor : undefined,
      } as StyleProp<ImageStyle>;
    }
    return {
      width: '100%',
      maxHeight: '100%',
      objectFit: 'cover',
      borderRadius: typeof radius !== 'undefined' && typeof radius !== 'boolean' ? unit(radius) : undefined,
      borderBottomLeftRadius: typeof radiusLeft !== 'undefined' && typeof radiusLeft !== 'boolean' ? radiusLeft : undefined,
      borderTopLeftRadius: typeof radiusLeft !== 'undefined' && typeof radiusLeft !== 'boolean' ? radiusLeft : undefined,
      borderBottomRightRadius: typeof radiusRight !== 'undefined' && typeof radiusRight !== 'boolean' ? radiusRight : undefined,
      borderTopRightRadius: typeof radiusRight !== 'undefined' && typeof radiusRight !== 'boolean' ? radiusRight : undefined,
      pointerEvents: 'none',
      backgroundColor: hasBackground ? backgroundColor : undefined,
    } as CSSProperties;
  }, [radius, hasBackground, backgroundColor, radiusRight, radiusLeft]);

  const renderLoader = useMemo(() => {
    if (isLoaded) {
      return null;
    }
    if (!hasWireframe) {
      return null;
    }
    return <View style={loadingStyleFinal} lightColor="#d2d2d2" darkColor="#232326" />;
  }, [isLoaded, hasWireframe]);

  const renderEmpty = useMemo(() => {
    if (!isEmpty || isLoaded) {
      return null;
    }
    return (
      <View style={emptyStyleFinal} lightColor="#d2d2d2" darkColor="#232326">
        <Icon name="image" weight="light" color="icon" />
      </View>
    );
  }, [isEmpty, isLoaded]);

  const renderHoverComponent = useMemo(() => {
    if (isEmpty || !isLoaded || !HoverComponent || !meta.url) {
      return null;
    }
    if (Platform.OS !== 'web') {
      return null;
    }
    return (
      <div
        onMouseEnter={handleHoverEnter}
        onMouseLeave={handleHoverLeave}
        style={{
          ...StyleSheet.flatten(styles.hoverStyle),
          transition: 'opacity 300ms ease',
          borderRadius: typeof radius !== 'undefined' && typeof radius !== 'boolean' ? unit(radius) : undefined,
          borderBottomLeftRadius: typeof radiusLeft !== 'undefined' && typeof radiusLeft !== 'boolean' ? radiusLeft : undefined,
          borderTopLeftRadius: typeof radiusLeft !== 'undefined' && typeof radiusLeft !== 'boolean' ? radiusLeft : undefined,
          borderBottomRightRadius: typeof radiusRight !== 'undefined' && typeof radiusRight !== 'boolean' ? radiusRight : undefined,
          borderTopRightRadius: typeof radiusRight !== 'undefined' && typeof radiusRight !== 'boolean' ? radiusRight : undefined,
          opacity: isHovered ? 1 : 0,
        }}
      >
        {HoverComponent}
      </div>
    );
  }, [isEmpty, isLoaded, HoverComponent, meta.url, hoverComponentOpacity, isHovered, radiusRight, radiusLeft, radius]);

  const renderChildren = useMemo(() => {
    if (!children) {
      return null;
    }
    return <View style={styles.children}>{children}</View>;
  }, [children]);

  return (
    <View style={containerStyle}>
      {Platform.OS !== 'web' && !!meta.url && (
        <Image key={meta.url} onLoad={handleLoadEnd} onError={handleLoadError} source={{ uri: meta.url }} resizeMode="cover" style={imageStyleFinal as StyleProp<ImageStyle>} />
      )}
      {Platform.OS === 'web' && !!meta.url && <img src={meta.url} onLoad={handleLoadEnd} onError={handleLoadError} alt="" style={imageStyleFinal as CSSProperties} loading="lazy" />}
      {renderChildren}
      {renderHoverComponent}
      {/* {renderLoader} */}
      {renderEmpty}
    </View>
  );
};

Picture.defaultProps = defaultProps;

const styles = StyleSheet.create({
  Poster: {
    position: 'relative',
    flexDirection: 'row',
    overflow: 'hidden',
  },
  children: {
    position: 'absolute',
    top: 0,
    right: 0,
    bottom: 0,
    left: 0,
  },
  empty: {
    position: 'absolute',
    alignItems: 'center',
    justifyContent: 'center',
    top: 0,
    right: 0,
    bottom: 0,
    left: 0,
  },
  loading: {
    position: 'absolute',
    width: '100%',
    alignItems: 'center',
    justifyContent: 'center',
    overflow: 'hidden',
    top: 0,
    right: 0,
    bottom: 0,
    left: 0,
  },
  hoverStyle: {
    position: 'absolute',
    display: 'flex',
    flexDirection: 'column',
    backgroundColor: 'rgba(0,0,0,0.25)',
    top: 0,
    right: 0,
    bottom: 0,
    left: 0,
  },
});

export default memo(Picture);
