import { useCallback, useMemo, type MouseEvent } from 'react';
import { type GestureResponderEvent } from 'react-native';

import { saveLastRoute, ui } from 'utils';
import { useDispatcher, useSelector } from 'store/utils/redux/hooks';
import * as userStore from 'store/nodes/user';
import { navigate } from 'navigation/methods';

export default (collectionId?: number | null) => {
  const dispatcher = useDispatcher();
  const userProfile = useSelector(userStore.selectors.getMy);

  const handleAddNotePress = useCallback(
    (event?: GestureResponderEvent, after?: { id: number; type: 'material' | 'note' }) => {
      if (!collectionId) {
        return;
      }
      if (!userProfile) {
        saveLastRoute();
        navigate('Auth/Start');
        return;
      }

      if (after) {
        dispatcher.playlist.newNote({ collectionId, options: { after } });
      } else {
        dispatcher.playlist.newNote({ collectionId });
      }
    },
    [collectionId, userProfile],
  );

  const handleRemovePress = useCallback(
    (event: GestureResponderEvent | MouseEvent<HTMLDivElement | HTMLButtonElement>, context: { id: number; type: 'material' | 'note' }) => {
      if (!collectionId) {
        return;
      }
      ui.confirm(`Are you sure want to remove\nthis ${context.type}?`).then((isConfirmed) => {
        if (!isConfirmed) {
          return;
        }
        dispatcher.playlist.removeItem({
          collectionId,
          itemType: context.type,
          itemId: context.id,
        });
      });
    },
    [collectionId],
  );

  return useMemo(
    () => ({
      handleAddNote: handleAddNotePress,
      handleRemove: handleRemovePress,
    }),
    [handleAddNotePress, handleRemovePress],
  );
};
