import { memo, useCallback, useEffect, useState } from 'react';

import Box from '@mui/joy/Box';
import { Virtuoso } from 'react-virtuoso';

import { useCollectionMethods } from 'hooks';

import { useSelector } from 'react-redux';
import { useDispatcher } from 'store/utils/redux/hooks';
import * as playlistStore from 'store/nodes/playlist';

import SortableVirtuosoList from 'components/SortableVirtuosoList';

import Head from './elements/Head';
import Item from './elements/Item';
import PendingMaterials from '../PendingMaterials';

type MaterialsProps = {
  resourceId: number;
};

const Materials = (props: MaterialsProps) => {
  const { resourceId } = props;

  const dispatcher = useDispatcher();

  const materialsMetadata = useSelector(playlistStore.selectors.materialsMetadataById(resourceId));
  const data = useSelector(playlistStore.selectors.dataById(resourceId));
  const { hasWrite, hasRead } = useSelector(playlistStore.selectors.userPermission(resourceId, 'my'));
  const { handleAddNote, handleRemove } = useCollectionMethods(resourceId);

  const handleAddMaterial = useCallback(
    (after?: { id: number; type: 'material' | 'note' }) => {
      if (!resourceId) {
        return;
      }
      if (after && after.id) {
        dispatcher.plus.open({ toCollection: { id: resourceId, after } });
        return;
      }
      dispatcher.plus.open({ toCollection: { id: resourceId, append: true } });
    },
    [resourceId],
  );

  const handleSort = useCallback(
    (oldIndex: number, newIndex: number) => {
      dispatcher.playlist.sortItems({
        oldIndex,
        newIndex,
        id: Number(resourceId),
      });
    },
    [resourceId],
  );

  const renderItem = useCallback(
    (args: any, draggable: boolean) => {
      return (
        <Item
          collectionId={resourceId}
          id={args.item.id}
          type={args.item.type}
          ref={args.ref}
          draggable={draggable}
          isRecommended={args.item.isSmartListRecommendation}
          isActive={args.isActive}
          isDragged={args.isDragged}
          isMy={draggable}
          props={args.props}
          handleProps={args.handleProps}
          onAddMaterialPress={(event, context) => handleAddMaterial(context)}
          onAddNotePress={handleAddNote}
          onRemovePress={handleRemove}
        />
      );
    },
    [handleAddNote, handleAddMaterial, handleRemove, resourceId],
  );

  return (
    <Box style={{ display: 'flex', flex: 1, flexDirection: 'column' }}>
      {hasWrite && <Head mt={3} mb={2.5} onAddMaterialPress={handleAddMaterial} onAddNotePress={handleAddNote} />}
      {hasWrite && <SortableVirtuosoList onSort={handleSort} items={materialsMetadata || []} renderItem={renderItem} m={-1} />}
      {hasRead && !hasWrite && (
        <Virtuoso
          useWindowScroll
          data={materialsMetadata || []}
          totalCount={materialsMetadata?.length}
          itemContent={(_, item) => renderItem({ item }, hasWrite)}
          style={{
            height: '100%',
            width: '100%',
          }}
        />
      )}
      {hasWrite && <PendingMaterials count={data?.pendingMaterialsCount} />}
    </Box>
  );
};

export default memo(Materials);
